import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '../../../../../components/ui/avatar'

import { cn } from '@/lib/utils'

export const _Avatar = ({ nome, src, className }: AvatarProps) => (
  <div className='rounded-full bg-gradient-to-r from-esforce-green-200 via-esforce-pink-100 to-esforce-white-100 p-0.5'>
    <Avatar className={(cn('size-12'), className)}>
      <AvatarImage
        src={src}
        alt='perfil usuario'
        className='object-cover object-center'
      />
      <AvatarFallback className='tw bg-black uppercase'>
        {nome.substring(0, 1)}
      </AvatarFallback>
    </Avatar>
  </div>
)

interface AvatarProps {
  nome: string
  src: string
  className?: string
}
