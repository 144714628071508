import { useForm } from 'react-hook-form'
import { CiSearch } from 'react-icons/ci'
import { Link, useNavigate } from 'react-router-dom'

import { CardHome } from '../../../../components/card'
import CarouselPremios from '../../../../components/carousel/premios'
import { UseAlertFillProfile } from '../../../../components/modal-ui/UseAlertFillProfile'
import { buttonVariants } from '../../../../components/ui/button'
import Container from '@/components/container'
import { Input } from '@/components/input'
import { Form } from '@/components/ui/form'

import { UseActiveUser } from '../../../../store/user.store'
import { Servicos } from '../servicos'
import { QuizPesquisas } from './QuizPesquisas'

import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { Popup } from '@/componentes/Popup/Popup'
import { rotasApp } from '@/configurações/rotasApp'
import { UseMenuActive } from '@/hooks/useMenuAtivo'

export const InícioPágina = () => {
  UseMenuActive('Home')
  const navigate = useNavigate()
  const { ModalAlert } = UseAlertFillProfile()
  const { userActive } = UseActiveUser()
  const formContext = useForm<{
    busca: string
  }>()
  const { watch, control, handleSubmit } = formContext
  const buscaWatch = watch('busca')

  function onSubmit() {
    if (typeof buscaWatch !== 'undefined') {
      localStorage.setItem('searchParam', buscaWatch)
      return navigate(rotasApp.prêmio)
    }
  }
  return (
    <>
      <ModalAlert />

      <Container className='items-center gap-7 bg-esforce-black-100 pb-3'>
        {/* <Cabeçalho /> */}
        <ContainerAppUi className='py-0'>
          <CarouselPremios />
          <Form {...formContext}>
            <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
              <div className='relative'>
                <Input
                  control={control}
                  registerName='busca'
                  placeholder='Pesquisar'
                  type='text'
                  className='rounded-xl border bg-transparent'
                />

                <div
                  className='absolute right-5 top-3 cursor-pointer text-esforce-green-200'
                  onClick={onSubmit}
                >
                  <CiSearch size={24} />
                </div>
              </div>
            </form>
          </Form>
          <Servicos />
        </ContainerAppUi>

        <CardHome
          title='Ranking'
          className='background-banner-ranking mb-7'
          link={rotasApp.pontos}
        />
        <CardHome
          link={rotasApp.materias}
          title='Matérias'
          className='background-banner-materias mb-7 flex items-center justify-start p-4'
        >
          <div className='flex flex-col gap-1 md:gap-4'>
            <Link
              to={rotasApp.materias}
              className={`${buttonVariants({
                size: 'xl',
                variant: 'default',
              })} mt-2 flex w-32 items-center gap-2 !bg-esforce-green-200 text-sm font-semibold !text-esforce-black-100 sm:w-60 md:w-44`}
            >
              Ver matérias
            </Link>
          </div>
        </CardHome>
        <CardHome
          link={rotasApp.extratoPontos}
          title='Meus pontos'
          className='background-banner-pontos mb-7 flex items-center justify-start p-4'
        >
          <div className='flex flex-col gap-1 md:gap-4'>
            <p className='font-dm-sans text-lg font-medium text-esforce-black-200'>
              Você possui
            </p>
            <p className='font-dm-sans text-4xl font-extrabold text-esforce-black-200'>
              {userActive?.Perfil?.Aluno?.saldoEmPontos}{' '}
              <small className='text-sm font-semibold'>pts</small>
            </p>
            <Link
              to={rotasApp.prêmio}
              className={`${buttonVariants({
                size: 'default',
                variant: 'default',
              })} mt-2 flex w-32 items-center gap-2 !bg-esforce-black-900 text-sm font-semibold text-esforce-white-100 sm:w-60 md:w-44`}
            >
              Trocar pontos
            </Link>
          </div>
        </CardHome>
        <QuizPesquisas />
      </Container>
      <Popup />
    </>
  )
}
