import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { CiSearch } from 'react-icons/ci'
import { useNavigate } from 'react-router-dom'

import Container from '@/components/container'
import { Input } from '@/components/input'
import LazyImage from '@/components/lazy-image'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { Skeleton } from '@/components/ui/skeleton'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'

import { PremioModel } from '@/@types/Models'
import goldCoin from '@/assets/gold-coin.svg'
import goldPrisma from '@/assets/gold-prisma.svg'
import gifImg from '@/assets/loading2.gif'
import prodSemImg from '@/assets/produto-sem-imagem.webp'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { Popup } from '@/componentes/Popup/Popup'
import { rotasApp } from '@/configurações/rotasApp'
import { usePremios } from '@/hooks/API/premio/usePrêmios'
import { UseMenuActive } from '@/hooks/useMenuAtivo'

export const PrêmioPágina = () => {
  UseMenuActive('Loja')

  const formContext = useForm<{
    busca: string
  }>()
  const { watch, control, handleSubmit, setValue } = formContext

  const buscaWatch = watch('busca')
  const buscar = buscaWatch && buscaWatch?.length > 2

  const { premios, isLoading } = usePremios({
    ...(buscar && { nome: buscaWatch }),
  })

  function onSubmit() {
    if (typeof buscaWatch !== 'undefined') {
      localStorage.setItem('searchParam', buscaWatch)
    }
  }

  useEffect(() => {
    setValue('busca', localStorage.getItem('searchParam') || '')
    const timer = setTimeout(() => {
      localStorage.removeItem('searchParam')
    }, 1000)
    return () => clearTimeout(timer)
  }, [])

  return (
    <Container className='h-full items-center gap-7 bg-esforce-black-100'>
      {/* <Cabeçalho /> */}
      <ContainerAppUi className='flex h-full flex-col gap-6 py-0'>
        <Form {...formContext}>
          <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
            <div className='relative'>
              <Input
                control={control}
                registerName='busca'
                placeholder='Pesquisar'
                type='text'
                className='rounded-xl border bg-transparent'
              />

              <div
                className='absolute right-5 top-3 cursor-pointer text-esforce-green-200'
                onClick={onSubmit}
              >
                <CiSearch size={24} />
              </div>
            </div>
          </form>
        </Form>
        <Tabs defaultValue='available' className='w-full'>
          <TabsList className='mb-6 grid w-full grid-cols-2 gap-3 bg-transparent'>
            <TabsTrigger
              className='borde h-10 rounded-xl border border-[#262626] bg-esforce-black-300/70 font-dm-sans text-sm font-semibold text-esforce-white-100 data-[state=active]:border-esforce-green-200 data-[state=active]:bg-esforce-green-200/20 data-[state=active]:text-esforce-green-200'
              value='available'
            >
              Disponíveis
            </TabsTrigger>
            <TabsTrigger
              className='borde h-10 rounded-xl border border-[#262626] bg-esforce-black-300/70 font-dm-sans text-sm font-semibold text-esforce-white-100 data-[state=active]:border-esforce-green-200 data-[state=active]:bg-esforce-green-200/20 data-[state=active]:text-esforce-green-200'
              value='all-gifts'
            >
              Todos os prêmios
            </TabsTrigger>
          </TabsList>
          <TabsContent
            value='available'
            className='hidden max-h-[calc(100vh-350px)] flex-col items-center gap-3 overflow-y-auto data-[state=active]:flex'
          >
            {isLoading ? (
              <>
                {Array.from({ length: 5 }).map((_, index) => (
                  <PremiosSkeleton key={index} />
                ))}
              </>
            ) : premios && premios.length > 0 ? (
              premios.filter((premio) => premio.estoque > 0).length > 0 ? (
                premios.map(
                  (premio) =>
                    premio.estoque > 0 && (
                      <PrêmioRender key={premio.id} premio={premio} />
                    ),
                )
              ) : (
                <p className='flex h-full items-center justify-center text-center font-dm-sans text-xl font-medium text-white'>
                  Nenhum produto encontrado como: {buscaWatch}
                </p>
              )
            ) : (
              <p className='flex h-full items-center justify-center text-center font-dm-sans text-xl font-medium text-white'>
                Nenhum produto encontrado como: {buscaWatch}
              </p>
            )}
          </TabsContent>
          <TabsContent
            value='all-gifts'
            className='hidden max-h-[calc(100vh-350px)] flex-col items-center gap-3 overflow-y-auto data-[state=active]:flex'
          >
            {isLoading ? (
              <PremiosSkeleton />
            ) : premios && premios.length > 0 ? (
              premios.map((premio) => (
                <PrêmioRender key={premio.id} premio={premio} />
              ))
            ) : (
              <p className='flex h-full items-center justify-center text-center font-dm-sans text-xl font-medium text-white'>
                Nenhum produto encontrado como: {buscaWatch}
              </p>
            )}
          </TabsContent>
        </Tabs>
      </ContainerAppUi>
      <Popup />
    </Container>
  )
}

export const PrêmioRender = (props: PrêmioRenderProps) => {
  const { premio } = props
  const navigate = useNavigate()

  return (
    <div className='flex min-h-60 w-full rounded-xl border border-esforce-pink-200/50'>
      <div className='flex-1'>
        <LazyImage
          src={premio.Fotos ? premio.Fotos[0].arquivoLink : prodSemImg}
          alt={premio.nome}
          className={`h-full w-full rounded-s-xl object-cover ${premio.estoque <= 0 && 'grayscale'} `}
          placeholder={gifImg}
        />
      </div>
      <div className='flex flex-1 flex-col justify-between rounded-e-xl bg-esforce-black-300 p-6'>
        <hgroup>
          <h3 className='mb-3 line-clamp-1 text-lg font-semibold capitalize text-white'>
            {premio.nome}
          </h3>
          <div className='flex flex-col gap-1'>
            <div className='flex items-center gap-2 text-sm'>
              <div
                className={`flex size-6 items-center justify-center gap-1 rounded-[6px] border border-esforce-green-200 bg-esforce-green-700 ${premio.estoque <= 0 && 'grayscale'} `}
              >
                <img
                  src={goldCoin}
                  alt='gold coin icon'
                  className={`size-2.5 ${premio.estoque <= 0 && 'grayscale'} `}
                />
              </div>
              <span className='text-xs font-normal text-esforce-white-100'>
                {premio.valorEmPontos} pontos
              </span>
            </div>
            <div className='flex items-center gap-2 text-sm text-zinc-300'>
              <div
                className={`flex size-6 items-center justify-center gap-1 rounded-[6px] border border-esforce-green-200 bg-esforce-green-700 ${premio.estoque <= 0 && 'grayscale'} `}
              >
                <img
                  src={goldPrisma}
                  alt='gold coin icon'
                  className={`w-2 ${premio.estoque <= 0 && 'grayscale'} `}
                />
              </div>
              <span className='text-xs font-normal text-esforce-white-100'>
                {premio.estoque} em estoque
              </span>
            </div>
          </div>
        </hgroup>

        <Button
          disabled={premio.estoque <= 0}
          size='xl'
          type='button'
          onClick={() => navigate(rotasApp.visualizarPrêmio(premio.id))}
          className={`mt-7 w-full cursor-pointer bg-esforce-green-200 text-sm font-bold text-esforce-black-200 hover:bg-esforce-green-100 ${premio.estoque <= 0 && 'grayscale'} `}
        >
          Trocar pontos
        </Button>
      </div>
    </div>
  )
}

const PremiosSkeleton = () => {
  return (
    <div className='borde-esforce-black-300 flex min-h-60 w-full rounded-xl border'>
      <Skeleton className='r h-full w-full flex-1 rounded-none rounded-s-xl bg-esforce-black-300' />

      <div className='flex flex-1 flex-col justify-between p-6'>
        <hgroup>
          <Skeleton className='mb-3 bg-esforce-black-300 p-4' />
          <div className='flex flex-col gap-1'>
            <div className='flex items-center gap-2 text-sm'>
              <Skeleton className='w-48 bg-esforce-black-300 p-3 text-xs font-normal text-esforce-white-100' />
            </div>
            <div className='flex items-center gap-2 text-sm text-zinc-300'>
              <Skeleton className='w-44 bg-esforce-black-300 p-3 text-xs font-normal text-esforce-white-100' />
            </div>
          </div>
        </hgroup>

        <Skeleton
          className={`mt-7 h-10 w-full cursor-pointer rounded-md bg-esforce-black-300 px-8 text-sm`}
        />
      </div>
    </div>
  )
}

interface PrêmioRenderProps {
  premio: PremioModel
}
