import { useForm } from 'react-hook-form'

import { Regex } from '../../../utilitários/Regex'

import { AlunoModel } from '@/@types/Models'
import { apiUpload } from '@/api/api'
import { queryClient } from '@/configurações/configuraçõesApi'
import { rotasApi } from '@/configurações/rotasApi'
import { responseError } from '@/utilitários/handler/responseHandler'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { z } from 'zod'

export const useAlunoEditar = () => {
  const {
    mutate: alunoEditarBase,
    isLoading: alunoEditarIsLoading,
    status: alunoEditarStatus,
  } = useMutation(endPoint, {
    onSuccess: async (data) => {
      await queryClient.invalidateQueries(['me'])
      await queryClient.invalidateQueries([`aluno-id_${data.id}`])
    },
    onError: (erro: AxiosError) => responseError(erro),
  })

  const alunoEditarContext = useForm<AlunoEditarSchema>({
    resolver: zodResolver(alunoEditarSchema),
    reValidateMode: 'onChange',
  })

  const alunoEditar = (data: AlunoEditarSchema) => {
    alunoEditarBase(data)
  }

  return {
    alunoEditar,
    alunoEditarIsLoading,
    alunoEditarContext,
    alunoEditarStatus,
  }
}

async function endPoint(data: AlunoEditarSchema): Promise<AlunoModel> {
  const result = await apiUpload().put(rotasApi.alunoAtualizar, data)
  return result.data.data
}

const alunoEditarSchema = z
  .object({
    id: z.string().min(1, { message: 'Campo obrigatório' }),
    matricula: z.string().optional(),
    nome: z.string().optional(),
    email: z
      .string()
      .email({ message: 'Email invalido' })
      .min(1, { message: 'Campo obrigatório' })
      .transform((email) => email.trim()),
    cpf: z.string().optional(),
    celular: z
      .string()
      .regex(Regex.CELULAR_MASCARA, { message: 'Celular inválido' })
      .min(1, { message: 'Campo obrigatório' }),
    idade: z.number().optional(),
    nomeResponsavel: z.string().optional(),
    cpfResponsavel: z
      .string()
      .regex(Regex.CPF_MASCARA, { message: 'CPF inválido' })
      .optional(),
    telefoneResponsavel: z
      .string()
      .regex(Regex.CELULAR_MASCARA, { message: 'Celular inválido' })
      .optional(),
    emailResponsavel: z
      .string()
      .email({ message: 'Email invalido' })
      .optional(),
  })
  .superRefine((data: any, ctx: any) => {
    if (data.idade < 18) {
      if (!data.nomeResponsavel) {
        ctx.addIssue({
          path: ['nomeResponsavel'],
          message: 'Campo obrigatório',
        })
      }
      if (!data.emailResponsavel) {
        ctx.addIssue({
          path: ['emailResponsavel'],
          message: 'Campo obrigatório',
        })
      }
      if (!data.cpfResponsavel) {
        ctx.addIssue({
          path: ['cpfResponsavel'],
          message: 'Campo obrigatório',
        })
      }
      if (!data.telefoneResponsavel) {
        ctx.addIssue({
          path: ['telefoneResponsavel'],
          message: 'Campo obrigatório',
        })
      }
    }
  })

export type AlunoEditarSchema = z.infer<typeof alunoEditarSchema>
