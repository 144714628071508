import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { UseModalUi } from '../../../components/modal-ui/UseModalUi'
import { Button } from '../../../components/ui/button'
import { Label } from '../../../components/ui/label'
import { Textarea } from '../../../components/ui/textarea'

import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { rotasApp } from '@/configurações/rotasApp'
import { useOuvidoria } from '@/hooks/API/ouvidoria/useOuvidoria'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'

export const OuvidoriaPage = () => {
  useContextoOutletLayoutComNavegação({
    título: 'Ouvidoria',
    isBotãoEsquerdo: true,
  })
  const { UiModal, onOpen } = UseModalUi()
  const [valorTextArea, setValorTextArea] = useState('')

  const navigate = useNavigate()
  const MINIMO_CARACTERES = 50

  const { enviarMensagem, isLoadingEnviarMensagem, statusEnviarMensagem } =
    useOuvidoria()

  useEffect(() => {
    if (statusEnviarMensagem === 'success') onOpen()
  }, [statusEnviarMensagem])

  console.log(valorTextArea)
  return (
    <ContainerAppUi>
      <div className='flex flex-col gap-5'>
        <div className='flex flex-col gap-2'>
          <form onSubmit={() => {}} className='flex flex-col gap-4'>
            <Label>Mensagem:</Label>
            <Textarea
              value={valorTextArea}
              onChange={({ target }) => setValorTextArea(target.value)}
              placeholder='Digite sua mensagem para a Ouvidoria'
              className='!bg-esforce-black-30 !h-48 w-full resize-none rounded-xl !bg-transparent bg-esforce-black-350 !text-base text-esforce-white-100'
            />
          </form>

          <div className='flex gap-2'>
            <p className='text-sm text-esforce-pink-50'>*</p>
            <p className='text-sm'>
              Sua mensagem deve conter no mínimo {MINIMO_CARACTERES} caracteres.
            </p>
          </div>
        </div>
        <Button
          size='xl'
          variant='default'
          onClick={() => enviarMensagem({ mensagem: valorTextArea })}
          className='mt-2 flex items-center gap-2 !bg-esforce-green-100 text-base font-semibold !text-esforce-black-100'
          disabled={
            valorTextArea.length < MINIMO_CARACTERES || isLoadingEnviarMensagem
          }
        >
          Enviar mensagem
        </Button>
        <UiModal
          callbackBotão={() => {
            setValorTextArea('')
            navigate(rotasApp.início)
          }}
          textoBotão='Entendido'
          descrição='Sua mensagem foi enviada com sucesso para a Ouvidoria!'
          título='Mensagem enviada!'
        />
      </div>
    </ContainerAppUi>
  )
}
