import { useCallback } from 'react'

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'

import { UseActiveUser } from '@/store/user.store'
import { AvatarProps } from '@radix-ui/react-avatar'

export const AvatarUsuárioAtivo = (props: AvatarUsuárioAtivoProps) => {
  const { userActive } = UseActiveUser()
  const avatarSrc = props.src || userActive?.Perfil?.Aluno?.avatarArquivoLink
  const RenderAvatar = useCallback(() => {
    return (
      <Avatar {...props}>
        <AvatarImage
          alt='perfil usuario'
          src={avatarSrc}
          className='object-cover object-center'
        />
        <AvatarFallback className='bg-black uppercase'>
          {userActive?.Perfil?.Aluno?.nome?.substring(0, 1)}
        </AvatarFallback>
      </Avatar>
    )
  }, [JSON.stringify(userActive), props])

  return <RenderAvatar />
}

interface AvatarUsuárioAtivoProps extends AvatarProps {
  src?: string
}
