
import { RouteObject } from 'react-router-dom'

import { Wellcome } from '../páginas/publicas/wellcome'

import { LayoutComMenu } from '@/componentes/LayoutComMenu'
import { LayoutComNavegação } from '@/componentes/LayoutComNavegação'
import { LayoutSemLayout } from '@/componentes/LayoutSemLayout'
import { rotasApp } from '@/configurações/rotasApp'
import { NotFound404Page } from '@/páginas/NotFound404'
import { Login } from '@/páginas/publicas/Login/login'
import { RecuperarSenhaCódigoPágina } from '@/páginas/publicas/Login/RecuperarSenhaCódigo.página'
import { RecuperarSenhaMatrículaPágina } from '@/páginas/publicas/Login/RecuperarSenhaMatrícula.página'
import { RecuperarSenhaNovaSenhaPágina } from '@/páginas/publicas/Login/RecuperarSenhaNovaSenha.página'
import { primeiroAcessoRoutes } from '@/páginas/publicas/primeiroAcesso/primeiroAcesso.routes'
import { SplashPágina } from '@/páginas/publicas/Splash'
import { TermosDeUsoPagina } from '@/páginas/publicas/TermosDeUso.pagina'
import { mapRotasPublicApp } from '@/utilitários/mapRotasApp'

const rotasLayoutComMenu: RouteObject[] = []

const rotasLayoutComNavegação: RouteObject[] = [
  {
    path: rotasApp.recuperarSenhaMatrícula,
    element: <RecuperarSenhaMatrículaPágina />,
  },
  {
    path: rotasApp.recuperarSenhaCódigo,
    element: <RecuperarSenhaCódigoPágina />,
  },
  {
    path: rotasApp.recuperarSenhaNovaSenha,
    element: <RecuperarSenhaNovaSenhaPágina />,
  },
]

const rotasSemLayout: RouteObject[] = [
  { path: '*', element: <NotFound404Page /> },
  { path: '/wellcome', element: <Wellcome /> },
  { path: '/splash', element: <SplashPágina /> },
  { path: '/termos-de-uso', element: <TermosDeUsoPagina /> },
]

const rotasLayoutSemLayout: RouteObject[] = [
  { path: rotasApp.login, element: <Login /> },
  ...mapRotasPublicApp(primeiroAcessoRoutes),
]

export const rotasPublicas: RouteObject[] = [
  ...rotasSemLayout,
  { element: <LayoutComMenu />, children: [...rotasLayoutComMenu] },
  { element: <LayoutComNavegação />, children: [...rotasLayoutComNavegação] },
  { element: <LayoutSemLayout />, children: [...rotasLayoutSemLayout] },
]
