import { useForm } from 'react-hook-form'

import { queryClient } from '../../../configurações/configuraçõesApi'
import { rotasApi } from '../../../configurações/rotasApi'

import { apiUpload } from '@/api/api'
import {
  responseError,
  responseSuccess,
} from '@/utilitários/handler/responseHandler'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { z } from 'zod'

interface Props {
  formData: FormData
  id: string
}
export const useAlunoImageEditar = () => {
  const { mutate: mutateEditarImagem, isLoading: IsLoadingEditarImagem } =
    useMutation(endPoint, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['me'])
        responseSuccess('Imagem de perfil editada com sucesso')
      },
      onError: (erro: AxiosError) => responseError(erro),
    })

  const editarImageContext = useForm<EditarImageSchema>({
    resolver: zodResolver(editarImageSchema),
    reValidateMode: 'onSubmit',
  })

  return {
    mutateEditarImagem,
    IsLoadingEditarImagem,
    editarImageContext,
  }
}

async function endPoint({ formData, id }: Props) {
  const result = await apiUpload().post(
    rotasApi.uploadImagePerfil(id),
    formData,
  )
  return result.data.data
}

export const editarImageSchema = z.object({
  avatar: z.any().optional(),
})

export type EditarImageSchema = z.infer<typeof editarImageSchema>
