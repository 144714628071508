import { useEffect, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { BiSolidJoystick } from 'react-icons/bi'
import { PiEyeLight, PiEyeSlash } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'

import Container from '../../../components/container'
import { Input } from '../../../components/input'
import { UseModalUi } from '../../../components/modal-ui/UseModalUi'
import { Button } from '../../../components/ui/button'
import { Form } from '../../../components/ui/form'

import { UseActiveUser } from '../../../store/user.store'

import { AvatarUsuárioAtivo } from '@/componentes/AvatarUsuárioAtivo'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { rotasApp } from '@/configurações/rotasApp'
import {
  AtualizarSenhaSchemaType,
  useAtualizarSenha,
} from '@/hooks/API/useAtualizarSenha'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { Loader2 } from 'lucide-react'
import ProfileCard from '../../../components/profile-card'

export const AlterarSenhaPágina = () => {
  const { userActive } = UseActiveUser()
  useContextoOutletLayoutComNavegação({
    título: 'Alterar senha',
    isBotãoEsquerdo: true,
  })
  const [atualPassword, setAtualPassword] = useState<boolean>(false)
  const [seePassword, setSeePassword] = useState<boolean>(false)
  const [confirmPassword, setConfirmPassword] = useState<boolean>(false)

  const {
    atualizarSenhaContext,
    atualizarSenha,
    atualizarSenhaStatus,
    atualizarSenhaIsLoading,
  } = useAtualizarSenha()
  const { handleSubmit, control } = atualizarSenhaContext

  const { UiModal, onOpen } = UseModalUi()
  const navigate = useNavigate()

  const onSubmit: SubmitHandler<AtualizarSenhaSchemaType> = (data) => {
    atualizarSenha(data)
  }

  useEffect(() => {
    if (atualizarSenhaStatus === 'success') onOpen()
  }, [atualizarSenhaStatus])

  return (
    <>
      <UiModal
        título='Tudo certo!'
        descrição='Sua senha de acesso foi alterada com sucesso. Utilize-a para acessar sua conta'
        textoBotão='Entendido'
        callbackBotão={() => navigate(rotasApp.meuPerfil)}
      />

      <ContainerAppUi className='overflow-x-hidden'>
        <Container>
        <ProfileCard />
          <Form {...atualizarSenhaContext}>
            <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
              <fieldset className='flex flex-col gap-4'>
                <div className='relative'>
                  <Input
                    control={control}
                    registerName='senhaAtual'
                    textlabel='Senha Atual'
                    placeholder='******'
                    type={!atualPassword ? 'password' : 'text'}
                  />

                  <button
                    type='button'
                    className='absolute right-5 top-11 cursor-pointer'
                    onClick={() => setAtualPassword((prev) => !prev)}
                  >
                    {!atualPassword ? (
                      <PiEyeLight size={24} />
                    ) : (
                      <PiEyeSlash size={24} />
                    )}
                  </button>
                </div>
                <div className='relative'>
                  <Input
                    control={control}
                    registerName='senhaNova'
                    textlabel='Senha Nova'
                    placeholder='******'
                    type={!seePassword ? 'password' : 'text'}
                  />

                  <button
                    type='button'
                    className='absolute right-5 top-11 cursor-pointer'
                    onClick={() => setSeePassword((prev) => !prev)}
                  >
                    {!seePassword ? (
                      <PiEyeLight size={24} />
                    ) : (
                      <PiEyeSlash size={24} />
                    )}
                  </button>
                </div>

                <div className='relative'>
                  <Input
                    control={control}
                    registerName='confirmarSenha'
                    textlabel='Confirmar senha'
                    placeholder='******'
                    type={!confirmPassword ? 'password' : 'text'}
                  />

                  <button
                    type='button'
                    className='absolute right-5 top-11 cursor-pointer'
                    onClick={() => setConfirmPassword((prev) => !prev)}
                  >
                    {!confirmPassword ? (
                      <PiEyeLight size={24} />
                    ) : (
                      <PiEyeSlash size={24} />
                    )}
                  </button>
                </div>
              </fieldset>

              <Button
                size='xl'
                type='submit'
                disabled={atualizarSenhaIsLoading}
                className='mt-7 w-full cursor-pointer bg-esforce-green-200 text-sm font-bold text-esforce-black-200 hover:bg-esforce-green-100'
              >
                {atualizarSenhaIsLoading ? (
                  <>
                    <Loader2 className='animate-spin' />
                  </>
                ) : (
                  'Enviar'
                )}
              </Button>
            </form>
          </Form>
        </Container>
      </ContainerAppUi>
    </>
  )
}
