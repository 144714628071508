import { useEffect } from 'react'
import { BiJoystick } from 'react-icons/bi'
import { LiaDotCircleSolid } from 'react-icons/lia'
import { RiTimerLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'

import { UseModalUi } from '../../../components/modal-ui/UseModalUi'

import { useVerifyQuizResposta } from '../quizPesquisas/responderQuiz/useVerifyQuizRespostas'

import { QuizPesquisaModel } from '@/@types/Models'
import goldquestion from '@/assets/gold-question.svg'
import sword from '@/assets/sword.svg'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { rotasApp } from '@/configurações/rotasApp'
import { useQuizPesquisaHistorico } from '@/hooks/API/quiz/useQuizPesquisaHistorico'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  status?: string
  quizPesquisa: QuizPesquisaModel
}

function CardItems({ status, quizPesquisa, ...rest }: CardProps) {
  const { verifyQuizResposta } = useVerifyQuizResposta({
    quizId: quizPesquisa.id,
  })

  return (
    <div
      className='flex h-auto w-full flex-col items-center justify-center gap-5 rounded-xl bg-esforce-black-300 p-3 font-dm-sans'
      {...rest}
    >
      <div className='flex w-full items-center justify-between gap-2'>
        <div className='flex flex-1 items-center gap-2 rounded-xl'>
          <div className='flex size-11 items-center justify-center gap-1 rounded-xl border-2 border-esforce-green-200 bg-esforce-green-700'>
            <img
              src={quizPesquisa.tipo === 'quiz' ? sword : goldquestion}
              alt='iconde de uma espada'
              className='size-5'
            />
          </div>
          <div>
            <p className='font-dm-sans text-sm font-semibold text-white'>
              {quizPesquisa.tema}
            </p>
            <p className='font-dm-sans text-xs font-normal text-[#737373]'>
              {status}
            </p>
          </div>
        </div>
        <div className='flex items-end justify-end gap-2 rounded-xl'>
          <div className='rounded-2xl border border-esforce-green-200 bg-esforce-green-200/20 px-3 py-1 font-medium text-esforce-green-200'>
            {verifyQuizResposta?.respostasCorretas || '0'}/
            {quizPesquisa.Perguntas?.length || '0'}
          </div>
        </div>
      </div>

      <ul className='border-esforce-black-400 flex w-full items-center justify-between gap-0.5 border-t pt-2.5 font-dm-sans text-xs font-normal text-esforce-grey-200'>
        {quizPesquisa.tipo === 'quiz' && (
          <li className='flex items-center justify-center gap-0.5'>
            <RiTimerLine size={16} />
            <span className=''>15 Sec</span>
          </li>
        )}
        <li className='flex items-center justify-center gap-0.5'>
          <BiJoystick size={16} />
          <span className=''>{quizPesquisa.Perguntas?.length} perguntas</span>
        </li>
        <li className='flex items-center justify-center gap-0.5'>
          <LiaDotCircleSolid size={16} />
          <span className=''>{quizPesquisa.pontuacao} Pontos</span>
        </li>
      </ul>
    </div>
  )
}

export const HistóricoPágina = () => {
  useContextoOutletLayoutComNavegação({
    título: 'Histórico',
    isBotãoEsquerdo: true,
  })

  const navigate = useNavigate()
  const { UiModal, onClose, onOpen } = UseModalUi()
  const { quizPesquisaHistorico } = useQuizPesquisaHistorico()

  const RenderModalSemHistórico = () => (
    <UiModal
      título='Ops!'
      descrição='Você ainda não possui nenhum histórico de quiz ou pesquisa.'
      textoBotão='Entendido'
      callbackBotão={() => {
        onClose()
        navigate(rotasApp.meuPerfil)
      }}
    />
  )

  useEffect(() => {
    if (quizPesquisaHistorico && quizPesquisaHistorico?.length === 0) {
      onOpen()
    }
  }, [JSON.stringify(quizPesquisaHistorico)])

  return (
    <ContainerAppUi>
      <div className='flex flex-col gap-5'>
        <RenderModalSemHistórico />
        {quizPesquisaHistorico?.map((quizPesquisa) => {
          const status = verificarStatusQuiz(quizPesquisa)
          return (
            <CardItems
              key={quizPesquisa.id}
              quizPesquisa={quizPesquisa}
              status={status}
              onClick={() =>
                quizPesquisa.tipo === 'quiz'
                  ? navigate(rotasApp.historicoQuiz(quizPesquisa.id))
                  : navigate(rotasApp.historicoPesquisa(quizPesquisa.id))
              }
            />
          )
        })}
      </div>
    </ContainerAppUi>
  )
}

function verificarStatusQuiz(quizPesquisa: QuizPesquisaModel) {
  const dataAtual = new Date()

  if (quizPesquisa.calculado === false) {
    if (quizPesquisa?.expiraEm && new Date(quizPesquisa.expiraEm) > dataAtual) {
      return 'Em andamento'
    } else {
      return 'Aguardando finalização'
    }
  } else {
    return 'Completo'
  }
}
