import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { BiSolidJoystick } from 'react-icons/bi'
import { FaTrash } from 'react-icons/fa'
import { TbPhotoEdit } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '../../../components/ui/avatar'
import Container from '@/components/container'
import { Input } from '@/components/input'
import { UseModalUi } from '@/components/modal-ui/UseModalUi'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { Label } from '@/components/ui/label'

import { useAlunoImageDelete } from '../../../hooks/API/aluno/useAlunoImageDelete'
import { useAlunoImageEditar } from '../../../hooks/API/aluno/useAlunoImagemEditar'

import gifImg from '@/assets/loading2.gif'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { rotasApp } from '@/configurações/rotasApp'
import { useAlunoBuscarPorId } from '@/hooks/API/aluno/useAlunoBuscarPorId'
import {
  AlunoEditarSchema,
  useAlunoEditar,
} from '@/hooks/API/aluno/useAlunoEditar'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { UseActiveUser } from '@/store/user.store'
import { Loader2 } from 'lucide-react'

export const EditarPerfilPágina = () => {
  useContextoOutletLayoutComNavegação({
    título: 'Editar perfil',
    isBotãoEsquerdo: true,
  })
  const fileInputRef = useRef<HTMLInputElement>(null)
  const { UiModal, onOpen } = UseModalUi()
  const { userActive } = UseActiveUser()
  const {
    alunoEditarContext,
    alunoEditar,
    alunoEditarIsLoading,
    alunoEditarStatus,
  } = useAlunoEditar()
  const [isEdição, setIsEdição] = useState<boolean>(false)
  const [imagePreview, setImagePreview] = useState<string>()

  const { setValue, watch, control, handleSubmit } = alunoEditarContext

  const navigate = useNavigate()

  const { mutateDeletarImagem, isLoadingDeletarImage } = useAlunoImageDelete()
  const { IsLoadingEditarImagem, mutateEditarImagem } = useAlunoImageEditar()

  const { aluno } = useAlunoBuscarPorId({
    alunoId: userActive?.Perfil?.alunoId,
  })

  const OnOpenYourFiles = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  useEffect(() => {
    const ediçãoEmail = watch('email') === aluno?.email
    const ediçãoCelular = watch('celular') === aluno?.celular
    if (ediçãoEmail && ediçãoCelular) setIsEdição(false)
    else setIsEdição(true)
  }, [watch()])

  useEffect(() => {
    const ediçãoEmail = watch('email') === aluno?.email
    const ediçãoCelular = watch('celular') === aluno?.celular
    const ediçãoNomeResponsavel =
      watch('nomeResponsavel') === aluno?.Responsavel?.nome
    const ediçãoEmailREsponsavel =
      watch('emailResponsavel') === aluno?.Responsavel?.email
    const ediçãoCPFResponsavel =
      watch('cpfResponsavel') === aluno?.Responsavel?.cpf
    const ediçãoTelefoneResponsavel =
      watch('telefoneResponsavel') === aluno?.Responsavel?.telefone

    if (
      ediçãoEmail &&
      ediçãoCelular &&
      ediçãoNomeResponsavel &&
      ediçãoEmailREsponsavel &&
      ediçãoCPFResponsavel &&
      ediçãoTelefoneResponsavel
    )
      setIsEdição(false)
    else setIsEdição(true)
  }, [watch()])
  console.log(aluno)

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (file) {
      const urlImage = URL.createObjectURL(file)
      setImagePreview(urlImage)
      const formData = new FormData()
      formData.append('avatar', file)
      if (aluno) mutateEditarImagem({ formData, id: aluno.id })
    }
  }

  const handleDeleteProfileImage = () => {
    setImagePreview(' ')
    if (aluno) {
      mutateDeletarImagem({ id: aluno.id })
    }
  }

  const onSubmit: SubmitHandler<AlunoEditarSchema> = (data) => {
    alunoEditar(data)
  }

  useEffect(() => {
    if (!aluno) return
    setValue('id', aluno.id)
    setValue('matricula', aluno.matricula)
    setValue('nome', aluno.nome)
    setValue('cpf', aluno.cpf)
    setValue('email', aluno.email ? aluno.email : '')
    setValue('celular', aluno.celular ? aluno.celular : '')
    setValue('idade', aluno.idade)

    if (!aluno.Responsavel) return
    setValue('nomeResponsavel', aluno.Responsavel.nome || '')
    setValue('emailResponsavel', aluno.Responsavel.email || '')
    setValue('cpfResponsavel', aluno.Responsavel.cpf || '')
    setValue('telefoneResponsavel', aluno.Responsavel.telefone || '')
  }, [JSON.stringify(aluno)])

  useEffect(() => {
    if (alunoEditarStatus === 'success') onOpen()
  }, [alunoEditarStatus])

  return (
    <>
      <UiModal
        callbackBotão={() => navigate(rotasApp.meuPerfil)}
        textoBotão='Entendido'
        descrição='Todos os ajustes foram feitos com sucesso!'
        título='Alterações salvas com sucesso!'
      />
      <ContainerAppUi className='overflow-x-hidden'>
        <Container>
          <div className='before:radial-gradient-green after:radial-gradient-pink relative z-[99] my-4 flex h-80 w-full flex-col rounded-xl bg-gradient-to-b from-esforce-pink-100 to-esforce-green-200 p-0.5 before:absolute before:-bottom-4 before:-left-14 before:-z-[99] before:size-[250px] before:rounded-full before:blur-2xl before:content-[""] after:absolute after:-right-28 after:-top-32 after:-z-[99] after:size-[350px] after:rounded-full after:blur-2xl after:content-[""]'>
            <div className='background-image-full relative flex h-full w-full flex-col items-center justify-between rounded-xl p-8'>
              <div className='relative'>
                <div className='group relative cursor-pointer'>
                  <Avatar
                    className='size-32 text-3xl'
                    onClick={OnOpenYourFiles}
                  >
                    <AvatarImage
                      className='object-cover object-center'
                      alt='perfil usuario'
                      src={
                        imagePreview !== undefined
                          ? imagePreview
                          : aluno?.avatarArquivoLink
                      }
                    />
                    <AvatarFallback className='bg-black uppercase'>
                      {isLoadingDeletarImage ? (
                        <img src={gifImg} />
                      ) : (
                        userActive?.Perfil?.Aluno?.nome?.substring(0, 1)
                      )}
                    </AvatarFallback>
                  </Avatar>
                  {/* <div
                    className='duration-400 absolute left-0 top-0 z-30 hidden h-full w-full items-center justify-center rounded-full transition-colors ease-out group-hover:flex group-hover:bg-slate-800/50'
                    onClick={OnOpenYourFiles}
                  >
                    <TbPhotoEdit size={32} className='' />
                  </div> */}
                </div>
                <div
                  className='absolute bottom-0 left-0 z-50 flex size-10 cursor-pointer items-center justify-center rounded-full bg-slate-500'
                  onClick={handleDeleteProfileImage}
                >
                  <FaTrash size={14} />
                </div>
              </div>
              <p className='mb-2 font-dm-sans text-xl font-semibold text-esforce-white-100'>
                {userActive?.Perfil?.Aluno?.nome}
              </p>
              <ul className='grid w-full grid-cols-5 items-center justify-between'>
                <li className='flex flex-col items-center gap-0.5'>
                  <BiSolidJoystick
                    size={28}
                    className='text-esforce-green-200'
                  />
                  <p className='font-dm-sans text-xs font-normal text-esforce-grey-200'>
                    Quizes
                  </p>
                  <p className='font-dm-sans text-xl font-semibold text-esforce-green-200'>
                    {userActive?.atividades.quiz}
                  </p>
                </li>

                <li className='col-span-3 flex flex-col items-center gap-0.5'>
                  <BiSolidJoystick
                    size={28}
                    className='text-esforce-green-200'
                  />
                  <p className='font-dm-sans text-xs font-normal text-esforce-grey-200'>
                    Atividades extras
                  </p>
                  <p className='font-dm-sans text-xl font-semibold text-esforce-green-200'>
                    {userActive?.atividades.pontoExtra}
                  </p>
                </li>
                <li className='flex flex-col items-center gap-0.5'>
                  <BiSolidJoystick
                    size={28}
                    className='text-esforce-green-200'
                  />
                  <p className='font-dm-sans text-xs font-normal text-esforce-grey-200'>
                    Pesquisas
                  </p>
                  <p className='font-dm-sans text-xl font-semibold text-esforce-green-200'>
                    {userActive?.atividades.pesquisa}
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div hidden>
            <input
              id='avatar'
              onChange={handleImageUpload}
              ref={fileInputRef}
              type='file'
              accept='image/*'
            />
          </div>

          <Form {...alunoEditarContext}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className='flex w-full flex-col gap-5'
            >
              <Label className='font-dm-sans text-xl font-semibold text-esforce-white-100'>
                Meus Dados
              </Label>
              <fieldset className='my-2 flex flex-col gap-4'>
                <div hidden>
                  <Input
                    disabled
                    control={control}
                    registerName='idade'
                    textlabel='Idade'
                    placeholder=''
                    type='text'
                  />
                </div>
                <Input
                  disabled
                  control={control}
                  registerName='nome'
                  textlabel='Nome'
                  placeholder=''
                  type='text'
                />
                <Input
                  disabled
                  control={control}
                  registerName='matricula'
                  textlabel='Matrícula'
                  placeholder=''
                  type='text'
                />
                <Input
                  control={control}
                  registerName='email'
                  textlabel='E-mail'
                  placeholder=''
                  type='text'
                />
                <Input
                  disabled
                  control={control}
                  registerName='cpf'
                  textlabel='CPF'
                  placeholder=''
                  mask='CPF'
                  type='text'
                />
                <Input
                  control={control}
                  registerName='celular'
                  textlabel='Celular'
                  placeholder=''
                  mask='PHONE'
                  type='text'
                />
              </fieldset>
              {aluno && aluno.idade < 18 && (
                <>
                  <Label className='mb-4 font-dm-sans text-xl font-semibold text-esforce-white-100'>
                    Dados do responsável
                  </Label>

                  <fieldset className='my-2 flex flex-col gap-4'>
                    <Input
                      control={control}
                      registerName='nomeResponsavel'
                      textlabel='Nome do Responsável *'
                      placeholder=''
                      type='text'
                    />
                    <Input
                      control={control}
                      registerName='emailResponsavel'
                      textlabel='Email do Responsável *'
                      placeholder=''
                      type='text'
                    />
                    <Input
                      control={control}
                      registerName='cpfResponsavel'
                      textlabel='CPF do Responsável *'
                      placeholder=''
                      mask='CPF'
                      type='text'
                    />
                    <Input
                      control={control}
                      registerName='telefoneResponsavel'
                      textlabel='Celular do Responsável *'
                      placeholder=''
                      mask='PHONE'
                      type='text'
                    />
                  </fieldset>
                </>
              )}
              <Button
                size='xl'
                type='submit'
                disabled={alunoEditarIsLoading}
                className='w-full cursor-pointer bg-esforce-green-200 font-archivo text-sm font-bold text-esforce-black-200 hover:bg-esforce-green-100'
              >
                {alunoEditarIsLoading ? (
                  <>
                    <Loader2 className='animate-spin' />
                  </>
                ) : (
                  'Atualizar Dados'
                )}
              </Button>
            </form>
          </Form>
        </Container>
      </ContainerAppUi>
    </>
  )
}
