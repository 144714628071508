/* cSpell:disable */
export const pathAuth = 'autenticado'

const autenticacao = {
  me: `/${pathAuth}/me`,
  logout: `/${pathAuth}/logout`,
  signUp: '/signUp',
  signIn: '/signIn',
  recuperarSenha: '/auth/recuperar-senha',
  redefinirSenha: '/auth/redefinir-senha',
  codigoAcesso: `/auth/codigo-acesso`,
  termosBuscar: `/termos`,
  privacidadeBuscar: `/privacidade`,
  regulamentoBuscar: `/regulamento`,
  regulamentoInstituicaoBuscar: `/${pathAuth}/regulamento-instituicao`,
}

const instituicao = {
  instituicaoListar: `/${pathAuth}/instituicoes`,
  instituicaoBuscarPorId: ({ instituicaoId }: Instituicao) =>
    `/${pathAuth}/instituicao/${instituicaoId}`,
  instituicaoCriar: `/${pathAuth}/instituicao`,
}

const turma = {
  turmaListar: `/${pathAuth}/turmas`,
  turmaBuscarPorId: ({ turmaId }: Turma) => `/${pathAuth}/turma/${turmaId}`,
  turmaCriar: `/${pathAuth}/turma`,
}

const materia = {
  materiaListar: `/${pathAuth}/materias`,
  materiaBuscarPorId: ({ materiaId }: Materia) =>
    `/${pathAuth}/materia/${materiaId}`,
  materiaCriar: `/${pathAuth}/materia`,
}

const pesquisa = {
  pesquisaListar: `/${pathAuth}/pesquisas`,
  minhasPesquisas: `/${pathAuth}/minhas-pesquisas`,
  pesquisaBuscarPorId: ({ pesquisaId }: Pesquisa) =>
    `/${pathAuth}/pesquisa/${pesquisaId}`,
  pesquisaCriar: `/${pathAuth}/pesquisa`,
  pesquisaPerguntasListar: ({ pesquisaId }: Pesquisa) =>
    `/${pathAuth}/pesquisa/${pesquisaId}/perguntas`,
  pesquisaPerguntaBuscarPorId: ({ pesquisaPerguntaId }: PesquisaPergunta) =>
    `/${pathAuth}/pesquisa/pergunta/${pesquisaPerguntaId}`,
  pesquisaRespostaCriar: `/${pathAuth}/pesquisa/resposta`,
  pesquisaRespostaBuscarPorId: ({ pesquisaRespostaId }: PesquisaResposta) =>
    `/${pathAuth}/pesquisa/resposta/${pesquisaRespostaId}`,
}

const nota = {
  notasCriar: `/${pathAuth}/notas`,
  notaBuscarPorId: ({ notaId }: Nota) => `/${pathAuth}/nota/${notaId}`,
  notasListar: `/${pathAuth}/notas`,
}

const quiz = {
  quizCriar: `/${pathAuth}/quiz`,
  quizBuscarPorId: ({ quizId }: Quiz) => `/${pathAuth}/quiz/${quizId}`,
  quizPesquisaListar: `/${pathAuth}/quizPesquisa/listar`,
  quizRespostaCriar: `/${pathAuth}/quiz/resposta`,
  pesquisaRepostaCriar: `/${pathAuth}/pesquisa/resposta`,
  quizStatus: ({ quizId }: { quizId?: string }) =>
    `/${pathAuth}/quiz-status/${quizId}`,
  quizPesquisaHistoricoAluno: `/${pathAuth}/quizPesquisa/historico`,
  quizPesquisaVisualizar: (id?: string) =>
    `/${pathAuth}/quizPesquisa/visualizar/${id}`,
}

const premio = {
  premioCriar: `/${pathAuth}/premio`,
  premioBuscarPorId: ({ premioId }: Premio) =>
    `/${pathAuth}/premio/${premioId}`,
  premioListar: `/${pathAuth}/premios`,
  premioAlunoCriar: `/${pathAuth}/premio/aluno`,
  premioAlunoBuscarPorId: ({ alunoId }: Aluno) =>
    `/${pathAuth}/premio/aluno/${alunoId}`,
  premioAlunoBuscarAlunoPremio: (premioId?: string) =>
    `/${pathAuth}/premio-aluno/${premioId}`,
  premioAlunoListar: `/${pathAuth}/premio/aluno`,
  premioResgatar: `/${pathAuth}/premio-resgatar`,
}

const quizzesPesquisas = {
  quizzesPesquisasRecentes: `/${pathAuth}/quizzes-pesquisas/recentes`,
}

const historicoPontuacao = {
  historicoPontuacaoListarPorAlunoId: ({ alunoId }: Aluno) =>
    `/${pathAuth}/historico-pontuacao/listarPorAlunoId/${alunoId}`,
}

const aluno = {
  alunoBuscarPorId: ({ alunoId }: Aluno) => `/${pathAuth}/aluno/${alunoId}`,
  alunoAtualizar: `/${pathAuth}/aluno/completar-primeiro-cadastro`,
  alunoAtualizarSenha: `/${pathAuth}/aluno/senha`,
  alunoAceitarTermos: `/${pathAuth}/aluno/aceitar-termos`,
  alunoDeletar: `/${pathAuth}/aluno/deletar`,
  alunoDeletarPublico: `/excluir-conta`,
  metrica: `/${pathAuth}/aluno/grafico/metrica-aluno`,
}

const profile = {
  uploadImagePerfil: (id: string) => `/${pathAuth}/upload-foto/aluno/${id}`,
  deleteImageProfile: (id: string) => `/${pathAuth}/delete-foto/aluno/${id}`,
}

const banner = {
  bannerListar: `/${pathAuth}/banner`,
}

const notificacao = {
  notificacaoListar: `/${pathAuth}/notificacoes`,
  notificacaoMarcarComoLida: `/${pathAuth}/notificacao/marcar-como-lida`,
  notificacaoCountNaoLinda: `/${pathAuth}/notificacao/count-nao-lidas`,
  notificacaoDeletar: (id: string) => `/${pathAuth}/notificacao/deletar/${id}`,
  notificacaoMarcarTodasComoLidas: `/${pathAuth}/notificacao/marcar-todas-como-lidas`,
}

const reforce = {
  reforceListar: `/${pathAuth}/reforce/listar`,
  reforceFavoritar: `/${pathAuth}/reforce/favoritar`,
  reforceCriarComentario: `/${pathAuth}/reforce/comentario`,
  reforceBuscarId: (id?: string) => `/${pathAuth}/reforce/${id}`,
}

const responsavel = {
  responsávelVerificarUUID: (id?: string) =>
    `/responsavel/verificar-uuid/${id}`,
  responsávelValidarCPF: '/responsavel/validar-cpf',
  responsávelAutorizarAluno: '/responsavel/autorizar',
}

export const rotasApiPlano = {
  listar: `/${pathAuth}/plano/listar`,
  buscarPorId: (id: string) => `/${pathAuth}/plano/buscar-id/${id}`,
  buscarPlanoPagamento: (planoId: string) =>
    `/${pathAuth}/plano/buscar-plano-pagamento/${planoId}`,
  comprar: `/${pathAuth}/plano/ponteza/comprar`,
  pagamentosAluno: `/${pathAuth}/plano/pagamentos-aluno`,
}

const popup = {
  popupMostrar: `/${pathAuth}/popup-mostrar`,
  popupMarcarComoLida: (id: string) =>
    `/${pathAuth}/popup/marcar-como-lido/${id}`,
}

const ranking = {
  rankingAlunoVisualizar: `/${pathAuth}/ranking-visualizar-aluno`,
}

export const rotasApi = {
  ...autenticacao,
  ...instituicao,
  ...turma,
  ...materia,
  ...pesquisa,
  ...nota,
  ...quiz,
  ...premio,
  ...quizzesPesquisas,
  ...historicoPontuacao,
  ...aluno,
  ...banner,
  ...notificacao,
  ...reforce,
  ...responsavel,
  ...popup,
  ...ranking,
  ...profile,
}

interface Instituicao {
  instituicaoId: string
}

interface Turma {
  turmaId: string
}

interface Materia {
  materiaId: string
}

interface Pesquisa {
  pesquisaId: string
}

interface PesquisaPergunta {
  pesquisaPerguntaId: string
}

interface PesquisaResposta {
  pesquisaRespostaId: string
}

interface Nota {
  notaId: string
}

interface Quiz {
  quizId: string
}

interface Premio {
  premioId: string
}

interface Aluno {
  alunoId: string
}
