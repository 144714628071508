import { FaHeadset } from 'react-icons/fa6'
import { RiMegaphoneLine } from 'react-icons/ri'
import { Link, useNavigate } from 'react-router-dom'

import { Button, buttonVariants } from '../../../components/ui/button'

import { rotasApp } from '../../../configurações/rotasApp'

export const Servicos = () => {
  const navigate = useNavigate()

  return (
    <div className='mt-4 flex w-full flex-col gap-4'>
      <div className='flex items-start gap-4'>
        <Link
          to='https://api.whatsapp.com/send?phone=5511945665619&text=Ol%C3%A1,%20preciso%20de%20ajuda !'
          target='_blank'
          className={`${buttonVariants({
            size: 'xl',
            variant: 'default',
          })} mt-2 flex w-full items-center gap-2 !bg-esforce-green-200 !text-base font-semibold !text-esforce-black-100`}
        >
          <FaHeadset />
          Suporte
        </Link>
        <Button
          onClick={() => navigate(rotasApp.ouvidoria)}
          size='xl'
          variant='default'
          className='mt-2 flex w-full items-center gap-2 !bg-esforce-green-200 text-base font-semibold !text-esforce-black-100'
        >
          <RiMegaphoneLine />
          Ouvidoria
        </Button>
      </div>
    </div>
  )
}
