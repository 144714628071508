import React from 'react'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import './styles.css'

import { useNavigate } from 'react-router-dom'

import { Autoplay, Pagination } from 'swiper/modules'

import { BannerModel } from '../../@types/Models'
import { rotasApp } from '../../configurações/rotasApp'
import { useBannerListarRecentes } from '../../hooks/API/banner/useBannerListarRecentes'

import { Swiper, SwiperSlide } from 'swiper/react'

export default function CarouselPremios() {
  const { banners } = useBannerListarRecentes()
  const navigate = useNavigate()

  const goToAnotherPage = (banner: BannerModel) => {
    if (banner.irPara === 'link') window.open(banner.link, '_blank')
    else if (banner.irPara === 'premio') {
      navigate(rotasApp.visualizarPrêmio(banner.premioId!))
    }
  }

  return (
    <div className=''>
      <Swiper
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        className='prodruct-visualization-carousel'
      >
        {banners?.map((banner, index) => (
          <SwiperSlide
            key={index}
            className='rounded-xl bg-gradient-to-tr from-esforce-pink-200 from-10% via-transparent via-50% to-esforce-pink-200 to-100% p-1.5'
          >
            <img
              src={banner.arquivoLink}
              alt={banner.arquivoUrl}
              className='w-full cursor-pointer rounded-xl md:h-80'
              onClick={() => goToAnotherPage(banner)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
