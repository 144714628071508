import { api } from '@/api/api'
import { queryClient } from '@/configurações/configuraçõesApi'
import { rotasApi } from '@/configurações/rotasApi'
import {
  responseError,
  responseSuccess,
} from '@/utilitários/handler/responseHandler'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

export const useAlunoImageDelete = () => {
  const { mutate: mutateDeletarImagem, isLoading: isLoadingDeletarImage } =
    useMutation(endPoint, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['me'])
        responseSuccess('Imagem de perfil excluida com sucesso')
      },
      onError: (error: AxiosError) => responseError(error),
    })

  return { mutateDeletarImagem, isLoadingDeletarImage }
}

async function endPoint(data: { id: string }): Promise<boolean> {
  const result = await api().delete(rotasApi.deleteImageProfile(data.id))
  return result.data.data
}
